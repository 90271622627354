<template>
  <div class="share-social">
    <div class="share-icons">
      <button
        v-if="
          $route.params.slug !== 'msutunnel' &&
          $route.params.slug !== '2021buickkaraoke' &&
          $route.params.slug !== '2021buickkaraokevolleyball'
        "
        class="social-icon icon-email"
        @click="$emit('shareEmail', 'shareEmail')"
      ></button>
      <button v-else class="social-icon icon-sms" @click="$emit('shareSMS', 'shareSMS')"></button>

      <button
        v-if="
          $route.params.slug !== 'msutunnel' &&
          $route.params.slug !== '2021buickkaraoke' &&
          $route.params.slug !== '2021buickkaraokevolleyball'
        "
        class="social-icon icon-sms"
        @click="$emit('shareSMS', 'shareSMS')"
      ></button>
      <button v-else class="social-icon icon-email" @click="$emit('shareEmail', 'shareEmail')"></button>
      <button
        class="social-icon icon-facebook"
        @click="facebookLink"
        v-if="!isKiosk && $route.params.slug !== 'jordan24'"
      ></button>
      <button
        class="social-icon icon-twitter"
        @click="twitterLink"
        v-if="!isKiosk && $route.params.slug !== 'jordan24'"
      ></button>
      <button
        class="social-icon icon-download"
        @click="share"
        v-if="!isKiosk && $route.params.slug === 'jordan24'"
      ></button>
      <button
        class="social-icon icon-instagram"
        @click="$emit('shareInsta', 'shareInsta')"
        v-if="
          !isKiosk &&
          $route.params.slug !== 'jackierobinson' &&
          $route.params.slug !== 'chevytc2022det' &&
          $route.params.slug !== 'chevycomicbook22det'
        "
      ></button>
      <button
        class="social-icon icon-linkedin"
        @click="shareOnLinkedin"
        v-if="
          !isKiosk &&
          ($route.params.slug === 'essilortransitions' ||
            $route.params.slug === 'brightdropphotoop' ||
            $route.params.slug === 'flywithakamai' ||
            $route.params.slug === 'biogen2023')
        "
      ></button>
      <button
        class="social-icon icon-tiktok"
        @click="$emit('shareTiktok', 'shareTiktok')"
        v-if="($route.params.slug === 'chevycma2022' || $route.params.slug === 'chevymlballstar2022') && !isKiosk"
      ></button>
      <!-- @tiktokDownload="tiktokDownload" -->
      <a
        :href="s3Link"
        v-if="
          $route.params.slug === 'essilorlenswithfriends' ||
          $route.params.slug === 'essilorvogue' ||
          $route.params.slug === 'essiloroakley' ||
          $route.params.slug === 'essilortransitions' ||
          $route.params.slug === 'essilorrayban' ||
          $route.params.slug === 'toyota23'
        "
        download
        class="social-icon icon-download"
        @click="$emit('shareDownload', 'shareDownload')"
      >
      </a>
      <div class="qr-code" v-if="isKiosk">
        <VueQRcode :scale="8" :maskPattern="4" :width="12" :value="shareURL" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueQRcode from 'vue-qrcode';

export default {
  name: 'ShareSocial',
  props: {},
  components: {
    VueQRcode,
  },
  head() {
    return {
      meta: [
        { property: 'og:title', content: 'This is a test' },
        { property: 'og:image', content: this.s3Link },
        { property: 'twitter:card', content: 'summary_large_image' },
      ],
    };
  },
  async created() {
    const URL = process.env.VUE_APP_MODE === 'kiosk' ? '/' : process.env.VUE_APP_ONLINE_URL;

    const res = await axios.get(`${URL}/assets/${this.assetID}`);
    this.s3Link = res.data.data.s3Link;
  },
  data() {
    return {
      s3Link: null,
      assetID: this.$route.params.assetID,
      shareType: '',
    };
  },
  computed: {
    shareURL() {
      return `${process.env.VUE_APP_FE_APP}/${this.$route.params.slug}/${this.assetID}`;
    },
    isKiosk() {
      return process.env.VUE_APP_MODE === 'kiosk';
    },
  },
  methods: {
    // tiktokDownload() {
    //   axios({ url: this.s3link, method: 'GET', responseType: 'blob' }).then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', `${this.$route.params.slug}.mp4`);
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   });
    // },
    facebookLink() {
      window.location.href = `${process.env.VUE_APP_ONLINE_URL}/facebook/login?assetId=${this.assetID}&s3Link=${this.s3Link}`;
    },

    twitterLink() {
      window.location.href = `${process.env.VUE_APP_ONLINE_URL}/twitter/login?assetId=${this.assetID}&s3Link=${this.s3Link}`;
    },

    tiktokLink() {
      window.location.href = `${process.env.VUE_APP_ONLINE_URL}/tiktok/login?assetId=${this.assetID}`;
    },

    shareOnLinkedin() {
      window.location.href = `https://www.linkedin.com/shareArticle?mini=true&url=${this.shareURL}`;
    },

    instaLink() {
      this.instagram = true;
      this.shareType = 'email';
      this.showModal = true;
    },
    isiOS() {
      return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
          navigator.platform,
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    },
    isMobile() {
      let check = false;
      // eslint-disable-next-line func-names
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a,
          ) ||
          // eslint-disable-next-line no-useless-escape
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          // eslint-disable-next-line nonblock-statement-body-position, curly
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    download(blob) {
      // use the s3link to trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${this.$route.params.assetID}.mp4`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async share() {
      const blob = await axios.get(this.s3Link, { responseType: 'blob' });
      const file = new File([blob.data], `${this.$route.params.assetID}.mp4`, { type: 'video/mp4' });
      const shareData = {
        files: [file],
      };
      if (!this.isiOS() || !this.isMobile() || !navigator.canShare || !navigator.canShare(shareData)) {
        this.download(file);
      } else {
        navigator.share(shareData);
      }
    },
    emailLink() {
      this.instagram = false;
      this.shareType = 'email';
      this.showModal = true;
    },
    smsLink() {
      this.shareType = 'sms';
      this.showModal = true;
    },
    showThanks() {
      this.showThankYou = true;
      clearTimeout(this.thankYouTimer);
      setTimeout(() => {
        this.showThankYou = false;
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.share-social {
  width: 100%;
  max-height: 145px;
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;

  .share-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 1;

    .social-icon {
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: 60px;
      height: 60px;
      border: none;
      outline: none;

      @include md-tablet {
        margin-right: 15px;
        width: 65px;
        height: 65px;
      }
    }

    @include md-tablet {
      justify-content: flex-start;
    }
  }
}

.qr-code {
  // margin: 0 auto 0 0;
  width: 120px;
  height: 120px;
  & > * {
    border-radius: 10px;
    width: 100%;
    height: auto;
  }
}
</style>
