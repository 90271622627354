<template>
  <div class="share-page">
    <component
      :is="shareComponent"
      :imageURL="s3Link"
      :assetID="assetID"
      :failedAsset="failedAsset"
      :metadata="metadata"
      v-if="!showThankYou"
    >
      <template v-slot:social>
        <component
          :is="socialComponent"
          @shareEmail="shareEmail"
          @shareInsta="shareInsta"
          @shareTiktok="shareTiktok"
          @shareSMS="shareSMS"
        />
      </template>
    </component>
    <component
      :is="modalComponent"
      v-if="share.showModal && !showThankYou"
      :type="share.type"
      @close="share.showModal = false"
      @success="showThanks"
      @tiktokSuccess="tiktokDownload"
      :assetID="assetID"
    ></component>

    <component :is="thankYouComponent" v-if="showThankYou"></component>
    <!-- <slot name="privacy" v-if="!showThankYou"></slot> -->
  </div>
</template>

<script>
import axios from 'axios';
import ShareThankYou from '@/components/ShareThankYou.vue';
import ShareCard from '../components/ShareCard.vue';
import ShareSocial from '../components/ShareSocial.vue';
import ShareModal from '../components/ShareModal.vue';

export default {
  name: 'Share',

  components: {
    ShareCard,
    ShareSocial,
    ShareModal,
    ShareThankYou,
  },
  metaInfo() {
    const meta = [
      {
        property: 'og:title',
        content: this.ogData.title,
      },
      {
        property: 'og:description',
        content: this.ogData.description,
      },
      // {
      //   property: 'og:image',
      //   content: `${this.imageSource}`,
      // },
      {
        property: 'twitter:card',
        content: 'summary',
      },
      {
        property: 'twitter:title',
        content: this.ogData.title,
      },
      {
        property: 'twitter:description',
        content: this.ogData.description,
      },
    ];
    if (this.s3Link?.toLowerCase().includes('.mp4')) {
      meta.push({
        property: 'og:image',
        content: `${process.env.VUE_APP_ONLINE_URL}/assets/${this.$route.params.assetID}/thumbnail`,
      });
      meta.push({
        property: 'og:image',
        content: `${process.env.VUE_APP_ONLINE_URL}/assets/${this.$route.params.assetID}/thumbnail`,
      });
      meta.push({
        property: 'og:video',
        content: `${this.s3Link}`,
      });
      meta.push({
        property: 'twitter:image',
        content: `${process.env.VUE_APP_ONLINE_URL}/assets/${this.$route.params.assetID}/thumbnail`,
      });
    } else {
      meta.push({
        property: 'og:image',
        content: `${this.imageSource}`,
      });
      meta.push({
        property: 'twitter:image',
        content: `${this.imageSource}`,
      });
    }
    const link = [];
    if (this.$route.params.slug === 'flywithakamai') {
      link.push({ rel: 'icon', href: '/themes/flywithakamai/favicon.ico' });
      link.push({ rel: 'icon', type: 'image/png', sizes: '16x16', href: '/themes/flywithakamai/favicon.ico' });
      link.push({ rel: 'icon', type: 'image/png', sizes: '32x32', href: '/themes/flywithakamai/favicon.ico' });
      link.push({ rel: 'apple-touch-icon', sizes: '180x180', href: '/themes/flywithakamai/favicon.ico' });
      link.push({ rel: 'mask-icon', href: '/themes/flywithakamai/favicon.ico' });
      link.push({ rel: 'manifest', href: '' });
    } else if (this.$route.params.slug === 'f5insider') {
      link.push({ rel: 'icon', href: '/themes/f5insider/favicon.ico' });
      link.push({ rel: 'icon', type: 'image/png', sizes: '16x16', href: '/themes/f5insider/favicon.ico' });
      link.push({ rel: 'icon', type: 'image/png', sizes: '32x32', href: '/themes/f5insider/favicon.ico' });
      link.push({ rel: 'apple-touch-icon', sizes: '180x180', href: '/themes/f5insider/favicon.ico' });
      link.push({ rel: 'mask-icon', href: '/themes/f5insider/favicon.ico' });
      link.push({ rel: 'manifest', href: '' });
    } else if (this.$route.params.slug === 'f5ai') {
      link.push({ rel: 'icon', href: '/themes/f5ai/favicon.ico' });
      link.push({ rel: 'icon', type: 'image/png', sizes: '16x16', href: '/themes/f5ai/favicon.ico' });
      link.push({ rel: 'icon', type: 'image/png', sizes: '32x32', href: '/themes/f5ai/favicon.ico' });
      link.push({ rel: 'apple-touch-icon', sizes: '180x180', href: '/themes/f5ai/favicon.ico' });
      link.push({ rel: 'mask-icon', href: '/themes/f5ai/favicon.ico' });
      link.push({ rel: 'manifest', href: '' });
    } else {
      link.push({ rel: 'icon', href: '/favicon.ico' });
      link.push({ rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' });
      link.push({ rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' });
      link.push({ rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' });
      link.push({ rel: 'mask-icon', href: '/safari-pinned-tab.svg' });
      link.push({ rel: 'manifest', href: '/site.webmanifest' });
    }
    return {
      title: this.ogData.title,
      link,
      meta,
    };
  },

  needSerialize: true,
  async created() {
    // let res;
    if (process.env.VUE_APP_MODE === 'kiosk') {
      await axios.get(`/assets/${this.$route.params.assetID}`).then((res) => {
        this.s3Link = res.data.data.s3Link;
        if (process.env.VUE_APP_MODE === 'kiosk') {
          this.metadata = res.data.data.metadata;
        }
        // this.metadata = JSON.parse(res.data.data.metadata);
        this.metadata = res.data.data.metadata;
      });
    } else {
      await axios.get(`${process.env.VUE_APP_ONLINE_URL}/assets/${this.$route.params.assetID}`).then((res) => {
        this.s3Link = res.data.data.s3Link;
        if (process.env.VUE_APP_MODE === 'kiosk') {
          this.metadata = res.data.data.metadata;
        } else if (res && res.data && res.data.data && res.data.data.event) {
          this.ogData.title = res.data.data.event.ogTitle;
          this.ogData.description = res.data.data.event.ogDescription;
        }
        // this.metadata = JSON.parse(res.data.data.metadata);
        this.metadata = res.data.data.metadata;
      });
    }

    // this.s3Link = res.data.data.s3Link;
    // if (process.env.VUE_APP_MODE === 'kiosk') {
    //   this.metadata = res.data.data.metadata;
    // }
    // // this.metadata = JSON.parse(res.data.data.metadata);
    // this.metadata = res.data.data.metadata;
  },
  data() {
    return {
      s3Link: null,
      share: {
        showModal: false,
        isInstagram: false,
        type: '',
      },
      showThankYou: false,
      failedAsset: false,
      metadata: null,
      ogData: {
        title: null,
        description: null,
      },
    };
  },
  computed: {
    imageSource() {
      // return this.isVideo ? JSON.parse(this.imageURL.metadata).thumbnail : this.s3Link;
      return this.isVideo ? this.imageURL.metadata.thumbnail : this.s3Link;
    },

    isVideo() {
      if (this.imageURL != null) {
        return this.imageURL.s3Link.toLowerCase().includes('.mp4');
      }
      return false;
    },
    isMobile() {
      if (window.matchMedia('only screen and (max-width: 767px)').matches) {
        return true;
      }
      return false;
    },

    isKiosk() {
      return process.env.VUE_APP_MODE === 'kiosk';
    },
    assetID() {
      return `${this.$route.params.assetID}`;
    },
    shareComponent() {
      const componentName = this.getURLComponent('ShareCard');
      if (this.$root.$options.components[componentName]) {
        return componentName;
      }

      return 'ShareCard';
    },
    socialComponent() {
      const componentName = this.getURLComponent('ShareSocial');
      if (this.$root.$options.components[componentName]) {
        return componentName;
      }
      return 'ShareSocial';
    },
    modalComponent() {
      const componentName = this.getURLComponent('ShareModal');
      if (this.$root.$options.components[componentName]) {
        return componentName;
      }
      return 'ShareModal';
    },
    thankYouComponent() {
      const componentName = this.getURLComponent('ShareThankYou');
      if (this.$root.$options.components[componentName]) {
        return componentName;
      }
      return 'ShareThankYou';
    },
  },
  async mounted() {
    let ref = 'direct';
    const parsedParams = {};
    if (this.$route.hash) {
      this.$route.hash
        .split('&')
        .map((part) => part.replace(/^#/, ''))
        .forEach((param) => {
          const parts = param.split('=');
          // eslint-disable-next-line prefer-destructuring
          parsedParams[parts[0]] = parts[1];
        });
      window.location.hash = '';
      ref = parsedParams.r;
    }
    if (this.$route.params.assetID) {
      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_ONLINE_URL}/tracking`,
        data: { assetId: this.$route.params.assetID, trackingRecordType: ref },
      });
    }
    if (this.$route.query.code) {
      this.share.isInstagram = false;
      this.share.type = 'tiktok';
      this.share.showModal = true;
    }
  },
  methods: {
    tiktokDownload() {
      axios.get(`https://api.social-gen.com/assets/${this.$route.params.assetID}/base64`).then((response) => {
        // console.log(response.data.data);
        fetch(response.data.data.base64)
          .then((res) => res.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${this.$route.params.slug}.mp4`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      });
    },
    getURLComponent(postfix) {
      const slug = `${this.$route.params.slug}`;
      return slug
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
        .concat(postfix)
        .toLowerCase();
    },
    shareTiktok() {
      this.share.isInstagram = false;
      this.share.type = 'tiktok';
      this.share.showModal = true;
    },
    shareEmail() {
      this.share.isInstagram = false;
      this.share.type = 'email';
      this.share.showModal = true;
    },
    shareInsta() {
      this.share.isInstagram = true;
      this.share.type = 'instagram';
      this.share.showModal = true;
    },
    shareSMS() {
      this.share.isInstagram = false;
      this.share.type = 'sms';
      this.share.showModal = true;
    },
    showThanks() {
      if (!this.isMobile) {
        this.showThankYou = true;
        clearTimeout(this.thankYouTimer);
        setTimeout(() => {
          this.showThankYou = false;
        }, 5000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.thank-you {
  @include sm-mobile {
    display: none;
  }
}
</style>
